/* Animation */

html {
  font: 5vmin/1.3 Serif;

}

body, head {
  font-size: 52px;
}

.App {
  margin-top:92.8px;
}

/* Projects Page */

.Project {
  z-index:1000;
  position:relative;
  display:flex;
  padding:50px;
  padding-top:3%;
  padding-left:6%;
  padding-right:6%;
  flex-wrap:wrap;
  justify-content:center;
}

.d-flex-justify-content-center {
  display:flex;
  justify-content:center;
  padding-top:25px;
  padding-bottom:10px;
}

.button-filter {
  margin-right:1%;
  margin-left:1%;
  z-index:1000;
  position:relative;
  cursor:pointer;
  font-family:Inter,sans-serif;
  font-size:15px;

  outline:none;
  border:none;
  border-radius:30px;
  padding-top:5px;
  padding-bottom:5px;
  padding-right:10px;
  padding-left:10px;
}

.button-filter:focus {
  background-color:orange;
  border:solid;
  border-color:white;
}




.App-header {
  z-index:9999;
  position:absolute;
}
/* Specific Project Page */

.project-header {
  z-index:9999;
  position:relative;
  color:white;
  margin:0px;
  margin-top:70px;
}

.project-container {
  display:flex;
}

.project-img {
  height:350px;
  z-index:9999;
  position:relative;
}

.project-left-grid {
  margin-left:19%;
  width:50%;
  
}

.project-right-grid {
  margin-top:150px;
  width:50%;
  margin-right:21%;
  flex-direction:row;
  justify-content:flex-end;
  display:flex;
}

.project-text {
  color:white;
  font-size:20px;
  margin-right:5%;
}

.project-code {
  display:flex;
  justify-content: center;
  margin-top:5%;
}

.code {
  color:white;
  font-size:25px;
  z-index:9999;
}

.back-button {
  z-index:9999;
  position:relative;
  margin-left:19.5%;
  margin-top:40px;
  font-size:20px;
}

.back-button-text {
  color:#e9963d;
}