/* Home Page Styling */

.home {
    min-height:calc(100vh - 172.8px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:row;
    font-family: Roboto, sans-serif;
    gap:15%;
    background-image: url('../static/background.jpg') ;
    flex-wrap:wrap;
    padding:40px;
  }

  @media screen and (max-width: 832px) {
    .home {
      align-items: flex-start;
      top:100px;
      padding-bottom:200px;
      min-height:calc(100vh - 332.8px);

    }
  }

  @media screen and (max-width: 927px) {
    .home {
      gap:4vh;

    }
  }

  .home img {
    width: 340px;
    height: 540px; /* Half of the width */
    object-fit: cover; /* Crop the image to cover the specified height */
    object-position: center top; /* Shift the fit to the center horizontally and to the top vertically */
    border-radius: 10px; /* Add border radius */
  }

  @media screen and (max-width: 390px) {
    .home img {
      width:100%;
      margin-bottom: 20px;

    }
  }

  @media screen and (max-width: 390px) {
    .home {
      align-items: center;
      padding-top: 40px; /* Optional: add some padding to create space above the image and text */
      padding-bottom: 40px;
      min-height:calc(100vh - 172.8px);
      gap:0;
      flex-direction:column;
    }
  }

  .home > * {
    text-align:center;
    color: #333333;
    letter-spacing:1.5px;
    vertical-align:middle;
  }

.name {
    margin:0px;
    margin-bottom:10px;
    font-size:52px;
    text-align:left;
  }

  .title {
    font-size:14px;
    text-align:left;
    margin:0px;
    margin-top:10px;
    width:360px;
  }

  @media screen and (max-width: 832px) {
    .name {
      font-size: 6vw;
      text-align:center
    }
    .title {
      text-align:center;
      width:100%
    }
  }

  .home-ctas {
    display:flex;
    flex-direction:row;
    gap:5px;
    margin-top:30px;
  }

  @media screen and (max-width: 832px) {
    .home-ctas {
      margin-top:10px;
      justify-content: center;
    }
  }

  .primary-button-home {
    background-color: #ed4824;
    height:40px;
    width:120px;
    border-radius:5px;
    border:0px;
    font-size:14px;
    cursor:pointer;
    color:white;
    font-weight:600;
  }

  .primary-button-home:hover {
    background-color: #da352d;
  }

  .primary-button-home-inner {
    text-decoration: none;
    color:white;
    display:flex;
    height:40px
  }

  .secondary-button-home {
    background-color: transparent;
    height:40px;
    width:120px;
    border-radius:5px;
    border:1px solid #333333;
    font-size:14px;
    cursor:pointer;
    color:#333333;
  }

  .secondary-button-home a {
    text-decoration: none;
    color:#333333;
  }

  .secondary-button-home-link {
    height:40px;
    display:flex;
    text-decoration:none;
    color:#333333;
  }

  .secondary-button-home:hover {
    background-color: rgba(212, 210, 209, 0.848);
  }