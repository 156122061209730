.whole-nav-container {
  margin:0px;
  padding:0px 8vw 0px 8vw;
  display:flex;
  font-size:16px;
  position:fixed;
  z-index:9999999;
  top:0px;
  width:84vw;
  background:white;
  justify-content:space-between;
  font-weight:500;
  align-items: center;
  height:92.8px;
  min-width:250px
}

.home-container {
  min-width:120px
}

.nav-link {
  text-decoration:none;
  color: #333333;
}

.no-scroll {
  overflow: hidden;
}
  