/* new */
@media screen and (min-width: 1000px) {
    .WebNavigation {
        display:flex;
        align-items: 'center';
        flex-direction:row;
    }
  }

@media screen and (max-width: 1000px) {
    .WebNavigation {
        display: none;
    }
  }

.WebNavigationList {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    padding: 0px 40px;
}

.WebNavigationList li {
    list-style: none;
}

.web-back-active {
    background-color: #ed4824;
    text-decoration:underline;
}

.web-nav-background {
    padding:20px 10px;
}

.button-holder {
    display:flex;
    align-items: center;
}

.web-nav-link {
    text-decoration:none;
    color: #333333;
  }
  
.web-nav-link:hover {
    text-decoration:underline;
  }

.primary-button {
    background-color: #ed4824;
    height:40px;
    width:100px;
    border-radius:5px;
    border:0px;
    color:white;
    font-size:14px;
    cursor:pointer;
}

.primary-button:hover {
  background-color: #da352d;
}