body {
    font-family: Arial, sans-serif;
    font-size: 16px;
  }
  
  .container {
    max-width: 800px;
    width: 80%;
    margin: 40px auto;
    padding: 20px;
    box-sizing: border-box;
    margin-top:92.8px
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggle-button {
    background-color: #337ab7;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .publications.list {
    display: flex;
    flex-direction: column;
  }
  
  .publications.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .publication {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    box-sizing: border-box;
    position: relative; /* Add this to position the icon */
  }
  
  .publications.grid .publication {
    margin-bottom: 0; /* Remove margin bottom in grid view */
  }
  
  .publication h2 {
    margin-top: 0;
    font-size: 18px;
  }
  
  .publication p {
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .go-to-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width:10px;
    height:10px;
  }
  
  .go-to-icon a {
    text-decoration: none;
    color: #337ab7;
  }
  
  .go-to-icon a:hover {
    color: #23527c;
  }

  .fa-arrow-right {
    width:10px;
    height:10px;
  }

  .icon-link {
    position: absolute; /* Position the icon absolutely */
    top: 0; /* Move it to the top */
    right: 0; /* Move it to the right */
    padding:10px;
    color: #666; /* Adjust the icon color as needed */
  }

  .tags {
    margin-top: 10px;
  }
  
  .tag {
    display: inline-block;
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px;
    color: #666;
  }