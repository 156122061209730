.whole-calendar {
  width: 84vw;
  height: calc(95vh - 92.8px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding:0px 8vw 5vh 8vw;
}

.calendar-container {
  width:100%;
  height: calc(100% - 82px);
  display:flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .calendar-container {
    height: 100%; /* remove the toggle button height from the calculation */
  }
}

.fc {
  width: 100%; /* make the calendar take up the full width of its parent */
  height: 100%; /* make the calendar take up the full height of its parent */
}

@media only screen and (max-width: 700px) {
  .fc .fc-toolbar-title {
    font-size: 1.25em !important; /* Your custom font size */
  }
}

.fc-prev-button {
  width:32px !important;
  height:32px !important;
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding:0px !important;
  border:0px !important;
}

.fc-icon-chevron-left {
  width:18px !important;
  height:18px !important;
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding:0px !important;
  border:0px !important;
}

.fc-next-button {
  width:32px !important;
  height:32px !important;
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding:0px !important;
  border:0px !important;
  margin:0px !important;
}

.fc-icon-chevron-right {
  width:18px !important;
  height:18px !important;
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding:0px !important;
  border:0px !important;
}

.fc-today-button {
  height:32px;
  padding:10px !important;
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size:16px !important;
}

.fc-toolbar-chunk {
  display:flex !important;
  flex-direction:row !important;
}

.loading-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width:100vw;
  }
  
  .toggle-button-container {
    z-index: 1;
    height:60px;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    width:100%;
    padding:20px 0px;
  }

  .toggle-icon {
    width:16px;
    height:16px;
  }

  /* Add this media query to your CSS */
@media only screen and (max-width: 700px) {
  .toggle-button-container {
    display: none;
  }
}
