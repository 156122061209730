.Error {
    display:flex;
    justify-content: center;
    align-items: center;
    width:100vw;
    height:100vh;
    flex-direction:column;
}

.Error-message {
  color:#333333;
  font-size:28px;
  margin:0px;
}

.Error-back {
  font-size:18px;
  text-decoration:underline;
  color:#333333;
}

.ProductPage {
  margin-top:92.8px;
}

.title-section {
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
}

.title-top {
  display:flex;
  height:calc(100vh - 92.8px);
  justify-content: space-between;
  flex-wrap:wrap;
  overflow:hidden;
}

.left-title {
  width:36%;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  justify-content:center;
  padding-left:8%;
  padding-right:1%;
  min-width:300px;
}

.left-title h1 {
  color:#6b6b6b;
  font-size:40px;
  font-weight:700;
  margin-bottom:0px;
}

.left-title h2 {
  color:#a5a5a5;
  font-size:20px;
  font-weight:400;
}

.right-title {
  width:46vw;
  min-width:400px;
  padding-right:8%;
  padding-left:1%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

.product-main-image {
  max-width: 46vw;
  max-height:80vh;
  display:flex;
  justify-content: center;
  align-items: center;
}

.title-lower-description {
  padding: 0% 8%;
}

.title-lower-description h3 {
  color:#4f4f4f;
  font-size:20px;
  font-weight:400;
  line-height:1.5;
}

.title-lower-description p {
  color:#4f4f4f;
  font-size:16px;
  line-height:1.5;
  margin-bottom:60px;
}

@media screen and (max-width: 869px) {
  .left-title {
    width:84vw;
    padding:0px 8%;
    min-width:84vw;
    align-items: center;
    text-align:center
  }

  .right-title {
    width:84vw;
    padding:0px 8%;
    min-width:84vw;
    justify-content: flex-start;
}
  .product-main-image {
    max-width:80vw;
  }
  .diverr {
    height:60vh;
  }
}

@media screen and (max-width: 630px) {
  .eon_next {
    width:300px;
    height: 540px; /* Half of the width */
    object-fit: cover; /* Crop the image to cover the specified height */
    object-position: left;
  }

  .lolly {
    width:70vw;
    height:calc(70vw*4/3);
    object-fit: cover; /* Crop the image to cover the specified height */
    object-position: calc(0% - 30px);
  }

}

.photo-section {
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.photo-of-section {
  width: calc(33.33vw - 6vw);
  min-width: 260px; /* Set a minimum width */
  overflow: hidden;
  position: relative;
  display:flex;
  justify-content: center;
  align-items: center;
  padding:3vw;
}

.photo-of-section img {
  max-width:100%;
  max-height: 60vh; /* Let the browser calculate the height to maintain the aspect ratio */
}

@media screen and (max-width: 1140px) {
  .photo-of-section {
    padding:20px;
  }
}

@media screen and (max-width: 350px) {
  .photo-of-section {
    width:80%;
  }
}

/* Old */
  .code-button {
    background-color: transparent;
    height:40px;
    width:120px;
    border-radius:5px;
    border:1px solid #333333;
    font-size:14px;
    cursor:pointer;
    color:#333333;
    margin-top:50px;
  }

  .code-button:hover {
    background-color: rgba(255, 115, 0, 0.848);

  }

  .code-button a {
    text-decoration: none;
    color:#333333;
  }
  
  .desc-section {
    background: white;
    font-family:Inter,sans-serif;
  }
  
  .section {
    padding:40px 0px 40px 0px;
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
  }
  
  .main-heading {
    padding-left:12%;
    width:10%;
    min-width:100px;
  }
  
  .main-heading h1 {
    font-size:30px;
    color:#000000;
  }
  
  .desc-right {
    padding-right:20%;
    padding-left:12%;
    width:45%;
    min-width:200px;
  }
  
  .desc-right h2 {
    color:#4f4f4f;
    font-size:20px;
  }
  
  .desc-right p {
    font-size:16px;
    color:#4f4f4f;
    line-height:1.5;
  }
  
  @media screen and (max-width: 923px) {
    .main-heading,.desc-right {
      width:75%;
      padding-right:12%
    }
  }
  
  .button-container {
    display:flex;
    justify-content:center;
    padding-bottom:50px;
  }