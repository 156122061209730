.Work-Grid {
    min-height: calc(100vh - 92.8px - 2rem); /* Assuming your nav is 80px tall */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }
  
  .work-grid-title {
    font-size: 16px;
    color: #333333;
    margin:0px;
    text-align: center;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
  transition: transform 0.2s, box-shadow 0.2s;
  text-decoration: none;
  color: inherit;
  color:#333333

}

.grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

  .grid-item p {
    text-align: center;
  }
  
  .grid-img {
    width: 100%;
    height: 150px;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    object-fit: cover;
  }
  
  .grid-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .grid-title {
    font-weight: bold;
    font-size:20px;
    margin-bottom: 0.5rem;
  }
  
  .grid-description {
    margin-bottom: 1rem;
    font-size:1em;
  }
  
  .grid-link {
    text-decoration: none;
    color: #337ab7;
  }
  
  .grid-link:hover {
    color: #23527c;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 950px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  
    .grid-item {
      justify-content: flex-start;
    }

  }