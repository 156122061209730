/* new */
@media screen and (max-width: 1000px) {
    .ResponsiveNavigation {
        display: flex;
        align-items: center;
        flex-direction:row;
    }
  }

@media screen and (min-width: 1001px) {
    .ResponsiveNavigation {display: none;}
  }

.hamburger-icon {
    height:24px;
    width:24px;
    cursor:pointer;
    color:#333333;
  }

.nav-responsive-item {
    background-color: transparent;
    border-width:0
}

/* pop up */
.nav-container {
    height:calc(100vh - 92.8px);
    width:100vw;
    position:fixed;
    top:92.8px;
    left:0px;
    background-color:#333333;
    margin:0px;
    display:flex;
    flex-direction:column;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    padding:0px;
    min-width:290.95px;
}

.res-nav-link {
    text-decoration:none;
    color:white;
    font-size:20px
}

.res-back-active {
    text-decoration: underline;
    color:white
}



  